'use client';
import classNames from '../classNames';

type Props = {
  color?: string;
  bgColor?: string;
  size?: string;
  className?: string;
};

const Tag: FC<Props> = ({ children, color, bgColor, size, className }) => {
  const getSize =
    size === '2xs'
      ? 'text-[9px] tracking-[0.0625rem] py-0 h-4'
      : 'text-[10px] tracking-[0.0313rem] py-1 h-6';
  return (
    <div
      className={classNames(
        'flex items-center gap-1 select-none text-goku bg-bulma rounded-[4px] leading-4 text-moon-10-caption uppercase font-medium px-2',
        color,
        bgColor,
        getSize,
        className,
      )}
    >
      {children}
    </div>
  );
};

export default Tag;

'use client';

import { useEffect } from 'react';
import { useInView } from 'react-hook-inview';
import useFeaturebaseStore from '@/client/hooks/useFeaturebaseStore';

const FeaturebaseWrapper: FC = ({ children }) => {
  const { isOpen, setOpenStore } = useFeaturebaseStore();
  const [ref, isVisible] = useInView({ unobserveOnEnter: true });

  useEffect(() => {
    if (isVisible && !isOpen) {
      setOpenStore();
    }
  }, [isVisible, isOpen, setOpenStore]);

  return (
    <div
      ref={ref}
      onClick={() => {
        window.postMessage({
          target: 'FeaturebaseWidget',
          data: {
            action: 'openFeedbackWidget',
          },
        });
      }}
    >
      {children}
    </div>
  );
};

export default FeaturebaseWrapper;
